/* eslint-disable no-nested-ternary */
import React from 'react';
import { checkEmpty, checkFilled, zeroBridge } from '../../../../assets';

import './style.css';
import constants from '../../../../styles/constants';


const Row = ({
  label,
  watts = 0,
  setOpenRegister,
  ind,
  inverted,
  multiplierLabel,
}) => {
  const w = Math.abs(Math.round(50 * (watts / 1200)));
  const widthBar = `${w}%`;
  const marginSet = watts < 0 ? `${50 - w}%` : '50%';
  const icon = inverted ? (
    <span className='invertIcon'>☑</span>
  ) : (
    <span className='invertIcon'>☐</span>
  );
  const backgroundColor = watts < 0
    ? constants.lightGreen
    : watts < 500
      ? constants.lightOrange
      : constants.lightRed;

  return (
    <div onClick={() => setOpenRegister(ind)} data-testid={`open-register-${ind}}`}>
      <div style={{ ...{ flex: 2, flexDirection: 'row', display:'flex' } }} className='clickable'>
        <div className='row-column'>
          <div className='row-row'>
            <p className='row-label'><small style={{color:constants.medBlue}}>{ind +1}.</small> {label||('no label')}</p>
          </div>
          <div className='row-halfBox'>
            <div>
              <img  src={zeroBridge}
                resizeMode="contain"
                className={'row-bg'} />
              <div>
                <div
                  style={{
                    ...{
                      width: widthBar,
                      height: 10,
                      backgroundColor,
                      marginLeft: marginSet,
                      position: 'relative',
                      top: -5,
                    },
                  }}
                >
                  <p />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            ...{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'space-around',
              height: 20,
            },
          }}
        >
          <p className='row-watts multiplierWidth'>{multiplierLabel}</p>
          <p className='row-watts wattsWidth'>{Math.round(watts)}</p>
          {icon}
        </div>
      </div>
    </div>
  );
};

export default Row;

