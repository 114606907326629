export default {
  darkBlue: '#0052ca',
  lightBlue: '#80c0ff',
  white: '#ffffff',
  medBlue: 'rgba(0, 82, 202, 0.5)',
  gray: '#d1d3d4',
  green: '#0db90d',
  lightGreen: '#85de9c',
  orange: '#ff6c00',
  lightOrange: '#ffb680',
  red: '#d83200',
  lightRed: '#ff8080',
  black: '#000000',
  whiteOut: 'rgba(255,255,255,.65)',
  interSemiBold: 'Inter-Regular'
};
