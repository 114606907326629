
import React, { useContext, useState, useRef } from 'react';

import Dropdown from '../../../Dropdown';
import AppContext from '../../context';
import styles from '../../../../styles/index.css';
import './style.css';
import { xIcon, activeArrow, disabledArrow } from '../../../../assets';
import {
  LifecycleStage,
  getRegisterType,
  REGISTER_TYPE
} from '../../../../utils/constants';
import constants from '../../../../styles/constants';

import getCurrentCircuitType, { validCircuitTypes} from './currentCircuitType';
// import CheckBox from '../CheckBox';

const localStyles = {};

const CheckBox = ({ isChecked, setIsChecked }) => {
  return (
    <div className='rf-checkboxContainer'>
      <div
        className={`rf-checkbox ${isChecked ? 'rf-checkboxChecked' : ''}`}
        onClick={() => setIsChecked((e) => !e)}
      />
    </div>
  );
};
const styleOverride = {};
// grid: if true, circuit type will be main
// production: if true, circuit type will be prod
// battery: if true, circuit type will be batt


function RowData({ label, data, dropdown = false }) {
  return dropdown ? (
    <div className='rf-row' style={{ marginBottom: -2 }}>
      <p  style={{width: '140px'}}className='rf-label'>{label}</p>
      {data}
    </div>
  ) : (
    <div className='rf-row'>
      <p className='rf-label'>{label}</p>
      <p className='rf-value' data-test-id={`row-data-${label}`}>{data}</p>
    </div>
  );
}

const RegisterForm = ({
  deviceName = '',
  registerIndex = NaN,
  clampOptions = [],
  branchClampOptions = [],
  onSave,
  bleData = {},
  closeRegisterForm = () => null,
  modalVisible = false,
  mainClampFactor,
  branchClampFactor
}) => {

  const { hubConfigs, publicService } = useContext(AppContext);

  const highOrStandardAccuracy = getRegisterType(registerIndex);
  const multiplierFactor = highOrStandardAccuracy === REGISTER_TYPE.HIGH_ACCURACY ? mainClampFactor : branchClampFactor;
  const clampOptionsFilteredByValidFactors = clampOptions
  // .reduce((acc, curr) => {
  //   if (curr && curr.niceName && curr.niceName in multiplierFactor) acc.push(curr);
  //   return acc;
  // }, []);


  const branchClampOptionsFilteredByValidFactors = branchClampOptions
  // .reduce((acc, curr) => {
  //   if (curr && curr.niceName && curr.niceName in multiplierFactor) acc.push(curr);
  //   return acc;
  // }, []);
  const liveDataKeyNumber = registerIndex;
  const ps = publicService;
  const { installation: { registers = [] } = {} } = hubConfigs && hubConfigs[deviceName] ? hubConfigs[deviceName] : {};
  const {
    label = '',
    clamp_definition = '',
    production = false,
    grid = false,
    battery = false,
    inverted = false,
    multiplier = 1,// highOrStandardAccuracy === 'main' ? 154.494 : 63.3425,
  } = registers[registerIndex] || {};

  const [newLabel, updateNewLabel] = useState(label);
  const newMultiplier = [
    { label: '1', value: 1},
    { label: '2', value: 2 },
  ];
  const [selectedMultiplier, updateSelectedMultiplier] = useState(multiplier);
  const currentCircuitType = getCurrentCircuitType(grid, production, battery);
  const [newCircuitType, setCircuitType] = useState(currentCircuitType);  
  const updateNewCircuitType = (a) => {
    if(a === validCircuitTypes.main) {
      updateSelectedMultiplier(1);
    }
    setCircuitType(a);
  }

  const [newClampDefinition, setNewClampDefinition] = useState(clamp_definition);
  const updateNewClampDefinition = (a) => {
    setNewClampDefinition(a);
  }
  const [newInverted, updateNewInverted] = useState(inverted);
  const [formSavingState, updateFormSavingState] = useState(
    LifecycleStage.Ready
  );

  const dropdownCircuitTypes = [
    {
      value: validCircuitTypes.batt,
      label: 'Battery',
    },
    {
      value: validCircuitTypes.cons,
      label: 'Consumption',
    },
    {
      value: validCircuitTypes.prodBreakerSide,
      label: 'Production (Breaker Side)',
    },
    {
      value: validCircuitTypes.prodLineSide,
      label: 'Production (Line Side)',
    },
    {
      value: validCircuitTypes.main,
      label: 'Main',
    },
  ];


  const updateRegister = async () => {
    updateFormSavingState(LifecycleStage.Running);
    const clamp = branchClampOptionsFilteredByValidFactors.concat(clampOptionsFilteredByValidFactors).filter(
      ({ value }) => value === newClampDefinition
    )[0]?.niceName;
    let payload = {
      label: newLabel,
      clamp_definition: clamp,
      inverted: newInverted,
      multiplier: typeof selectedMultiplier === 'object' ? selectedMultiplier.value : selectedMultiplier,
    };
    if (newCircuitType === validCircuitTypes.cons) {
      payload = {
        ...payload,
        grid: false,
        battery: false,
        production: false,
      };
    }
    if (newCircuitType === validCircuitTypes.main) {
      payload = {
        ...payload,
        grid: true,
        battery: false,
        production: false,
      };
    }
    if (newCircuitType === validCircuitTypes.prodBreakerSide) {
      payload = {
        ...payload,
        grid: false,
        battery: false,
        production: true,
      };
    }
    if (newCircuitType === validCircuitTypes.prodLineSide) {
      payload = {
        ...payload,
        grid: true,
        battery: false,
        production: true,
      };
    }
    if (newCircuitType === validCircuitTypes.batt) {
      payload = {
        ...payload,
        grid: false,
        battery: true,
        production: false,
      };
    }
    const data = {
      body: { ...payload },
      registerIndex,
      hubId: deviceName.replace('curb-', ''),
    };
    try {
      const updatedPackage = {
        registerIndex: data.registerIndex,
        hubId: data.hubId,
        body: {
          inverted: data.body.inverted,
          battery: data.body.battery,
          production: data.body.production,
          grid: data.body.grid,
          multiplier: data.body.multiplier,
          clamp_definition: data.body.clamp_definition,
        }
      };
      if(typeof updatedPackage.body.multiplier === "string") updatedPackage.body.multiplier=parseInt(updatedPackage.body.multiplier);
      if (data.body.label) updatedPackage.body.label = data.body.label;
      try {
        await ps.patchRegister(updatedPackage); 
      } catch (e) {
        // this throws 400s when it works? the api hcs not us
      }
      onSave();
      updateFormSavingState(LifecycleStage.Finished);
    } catch (e) {
      updateFormSavingState(LifecycleStage.Error);
      throw new Error(e);
    }
  };
  if (Number.isNaN(Number(registerIndex))) {
    return null;
  }
  return (
    <div className='rf-centereddiv'>
      <div
        transparent
        visible={modalVisible}
        style={{'visibility': modalVisible? 'block' : 'none', 'display': modalVisible? 'block' : 'none'}}
        onRequestClose={() => {
          closeRegisterForm();
        }}
      >
        <div className='rf-greyOut'>
          <div className='rf-modaldiv'>
            <div
              data-testid='close-register-form'
              onClick={() => closeRegisterForm()}
              className='rf-closeIconContainer'
            >
              <img className='rf-xIcon' src={xIcon} />
            </div>
            <div className='rf-formContainer1'>
              <input
                data-testid='register-label'
                placeholder="Register Label"
                placeholderpColor={constants.darkBlue}
                autoCapitalize="none"
                autoCorrect={false}
                type='text'
                value={newLabel}
                onChange={(e) => {updateNewLabel(e.target.value)}}
                className='rf-textInput'
                style={{
                  width: '236px',
                  textAlign: 'left',
                  color: constants.darkBlue,
                }}
              />
              {newCircuitType !== validCircuitTypes.main && (
                <>
                  <div style={{height: 30}} />
                  <RowData
                    label="Multiplier:"
                    data={(
                      <div className='rf-dropdownMover'>

                        <Dropdown
                          // selectedpStyle={localStyles.selectedpStyle}
                          dataTestid='multiplier-dropdown'
                          optionList={newMultiplier}
                          onChange={updateSelectedMultiplier}
                          current={`${selectedMultiplier}`}
                          styleOverride={styleOverride||{}}
                          iconColor={constants.white}
                          // containerStyle={localStyles.containerStyle}
                        />
                      </div>
                    )}
                  />
                </>
              )}
              <>
                <div style={{height: 40}} />
                <RowData
                  label="Circuit Type:"
                  data={(
                    <div className='rf-dropdownMover'>
                      <Dropdown
                        // selectedpStyle={localStyles.selectedpStyle}
                        dataTestid='circuit-type-dropdown'
                        optionList={dropdownCircuitTypes}
                        onChange={updateNewCircuitType}
                        current={dropdownCircuitTypes.filter(({ value }) => value === newCircuitType)[0].value}
                        styleOverride={styleOverride}
                        iconColor={constants.white}
                        // containerStyle={localStyles.containerStyle}
                      />
                    </div>
                  )}
                  dropdown
                />
              </>
              
              {highOrStandardAccuracy === REGISTER_TYPE.HIGH_ACCURACY && clampOptions.length > 0 && (
                <RowData
                  label="CT Clamp:"
                  data={(
                    <div className='rf-dropdownMover'>
                      <Dropdown
                        // selectedpStyle={localStyles.selectedpStyle}
                        dataTestid='ct-clamp-dropdown'
                        optionList={clampOptionsFilteredByValidFactors.map(({ niceName, label, value }) => ({ label, value }))}
                        onChange={updateNewClampDefinition}
                        current={newClampDefinition}
                        styleOverride={styleOverride}
                        iconColor={constants.white}
                        // containerStyle={localStyles.containerStyle}
                      />
                    </div>
                  )}
                  dropdown
                />
              )}
              {highOrStandardAccuracy === REGISTER_TYPE.STANDARD_ACCURACY && clampOptions.length > 0 && (
                <RowData
                  label="CT Clamp:"
                  data={(
                    <div className='rf-dropdownMover'>
                      <Dropdown
                        dataTestid='ct-clamp-dropdown'
                        selectedpStyle={localStyles.selectedpStyle}
                        optionList={branchClampOptionsFilteredByValidFactors}
                        onChange={updateNewClampDefinition}
                        current={branchClampOptionsFilteredByValidFactors.filter(({value})=>value===newClampDefinition)[0]}
                        styleOverride={styleOverride}
                        iconColor={constants.white}
                        containerStyle={localStyles.containerStyle}
                      />
                    </div>
                  )}
                  dropdown
                />
              )}
              <div className='rf-column'>
                {newCircuitType === validCircuitTypes.main && (
                  <RowData
                    label="Inverted:"
                    data={(
                      <CheckBox
                        data-testid='inverted-checkbox'
                        isChecked={newInverted}
                        setIsChecked={() => updateNewInverted((e) => !e)}
                      />
                    )}
                  />
                )}
                {highOrStandardAccuracy === REGISTER_TYPE.HIGH_ACCURACY && (
                  <>
                    <RowData
                      label="Volts:"
                      data={bleData[`v${liveDataKeyNumber}`]}
                    />
                    <RowData
                      label="Watts:"
                      data={bleData[`p${liveDataKeyNumber}`]}
                    />
                    <RowData
                      label="Amps:"
                      data={bleData[`i${liveDataKeyNumber}`]}
                    />
                    <RowData
                      label="PowerFactor:"
                      data={bleData[`pf${liveDataKeyNumber}`]}
                    />
                    <RowData
                      label="Frequency:"
                      data={bleData[`f${liveDataKeyNumber}`]}
                    />
                  </>
                )}
              </div>
              {highOrStandardAccuracy === REGISTER_TYPE.STANDARD_ACCURACY && (
                <div className='rf-column'>
                  <RowData
                    label="Watts:"
                    data={bleData[`p${liveDataKeyNumber}`]}
                  />
                  <RowData
                    label="Amps:"
                    data={bleData[`i${liveDataKeyNumber}`]}
                  />
                </div>
              )}
              {formSavingState === LifecycleStage.Running && (
                <div
                  className='rf-buttonContainer rf-disabled'
                  style={{
                    ...{
                      marginTop: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <p style={styles.mainButton}>Saving</p>
                  <img
                    className='rf-buttonArrow'
                    src={disabledArrow}
                    style={{
                      ...{ marginTop: 19, marginRight: 20 },
                    }}
                  />
                </div>
              )}
              {formSavingState === LifecycleStage.Ready && (
                <div
                 className='rf-buttonContainer'
                 data-testid='save-button'
                  style={{
                    ...{
                      marginTop: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => updateRegister()}
                >
                  <button className={'rf-mainButton'}>Save</button>
                  {/* <img
                    src={activeArrow}
                    className='rf-buttonArrow'
                    style={{
                      ...{ marginTop: 19, marginRight: 20 },
                    }}
                  /> */}
                </div>
              )}
              {formSavingState === LifecycleStage.Error && (
                <div
                  data-testid='after-error-save-button'
                  className='rf-buttonContainer'
                  style={{
                    ...{
                      marginTop: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                  }}
                  onClick={() => updateRegister()}
                >
                  <p style={styles.mainButton}>
                    Error - Try Saving Again
                  </p>
                  <img
                    src={activeArrow}
                    className='rf-buttonArrow'
                    style={{
                      ...{ marginTop: 19, marginRight: 20 },
                    }}
                  />
                </div>
              )}
              {formSavingState === LifecycleStage.Finished && (
                <div
                  className='rf-buttonContainer rf-disabled'
                  style={{
                    ...{
                      marginTop: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                  }}
                  onClick={() => updateRegister()}
                  data-testid='after-success-save-button'
                >
                  <p className={'rf-mainButton'}>
                    Finished
                  </p>
                  <img
                    src={disabledArrow}
                    className='rf-buttonArrow'
                    style={{
                      ...{ marginTop: 19, marginRight: 20 },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
