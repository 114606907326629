/* eslint-disable import/prefer-default-export */

export const BLE_PREFIX = 'curb';

export const LifecycleStage = {
  Ready: 'Ready', // has never ran
  Running: 'Running',
  Finished: 'Finished',
  Error: 'Error'
};

export const LIVE_STREAM_ENDPOINT = 'curb-debug';

export const CURB_CONFIG_ENDPOINT = 'curb-config';

export const BLE_GATTS = {
  CURB_DEBGUB: 'ACDC0006-5C38-C37E-45A6-9EC5sAEEED99',
  CURB_CONFIG: 'ACDC0005-5C38-C37E-45A6-9EC5sAEEED99'
};

export const CONNECTION_METHOD = {
  WIFI: 'WIFI',
  BLE: 'BLE'
};

export const REGISTER_TYPE = {
  HIGH_ACCURACY: 'high-accuracy',
  STANDARD_ACCURACY : 'standard-accuracy'
}

export function getRegisterType(index) {
  return index <= 1 ? REGISTER_TYPE.HIGH_ACCURACY : REGISTER_TYPE.STANDARD_ACCURACY;
}

export const CT_CLAMP = {
  ROGOWSKI80100: 'ROGOWSKI80100', // 100A Rogowski
  XIAMEN100: 'XIAMEN100', // 100A
  XIAMEN50: 'XIAMEN50', // 50A
  XIAMEN30: 'XIAMEN30', // 30A
};
