import React, { useState } from 'react';
import DownloadCSVModal from '../CSVModal';
import { FiMenu } from "react-icons/fi";
import { useAuth0 } from "@auth0/auth0-react";

const Menu = ({locationId, publicService, locationQuerySegment}:{locationId:string, publicService:any, locationQuerySegment:any}) => {
  // const { logout } = useAuth0();
  // const [openMenu, setOpenMenu] = useState(false);
  // const [openDownloadCSVModal, setOpenDownloadCSVModal] = useState(false);
  // const closeMenu = () => {
  //   setOpenMenu(false);
  //   setOpenDownloadCSVModal(false);
  // }
  return null;
  // return (
  //   <>
  //     <div style={{width: '100%'}} id={'menuContainer'}>
  //       <span className={openMenu ? 'active menubutton' : 'menubutton closed'} data-testid="menu-button" onClick={() => setOpenMenu(!openMenu)}><FiMenu /></span>
  //       <div className={`main-menu full-screen ${!openMenu? 'closeMenu': ''}`} data-testid="main-menu">
  //         <ul className="link-list main">
  //           <li onClick={() => logout({ returnTo: window.location.origin })}>
  //             <i className="fa fa-fw fa-sign-out" />
  //             Log Out
  //           </li>
  //           <li onClick={() => setOpenDownloadCSVModal(!openDownloadCSVModal)}>
  //             <i className="fa fa-fw fa-download" />
  //             Download CSV
  //           </li>
  //           <li data-testid="close-button" onClick={() => closeMenu()}> 
  //             Close Menu
  //           </li>
  //         </ul>
  //       </div>
  //     </div>
  //     {openDownloadCSVModal && <DownloadCSVModal close={closeMenu} locationId={locationId} publicService={publicService} querySegmentResponse={locationQuerySegment} />}
  //   </>
  // );
}



  export default Menu;