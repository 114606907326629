// grid: if true, circuit type will be main
// production: if true, circuit type will be prod
// battery: if true, circuit type will be batt
const validCircuitTypes = {
    main: 'main',
    batt: 'batt',
    prodLineSide: 'prodLineSide',
    prodBreakerSide: 'prodBreakerSide',
    cons: 'cons',
  };

export default (grid, production, battery) =>{
    // if grid && production, then it is a line side solar etc
    if(grid && production){
        return validCircuitTypes.prodLineSide;
    }
    if(production){
        return validCircuitTypes.prodBreakerSide;
    }   
    if(grid){
        return validCircuitTypes.main;
    }
    if(battery){
        return validCircuitTypes.batt;
    }
    return validCircuitTypes.cons;
}

export { validCircuitTypes};

